<template>
  <div>
    <section class="section-info section-info-sm" id="redirection">
      <div class="jcard-main jcard-dark">
        <div class="duke-spinner">
          <img src="@/assets/spinners/no-holes-square/tourbillon-grey.svg" alt="Tourbillon" />
        </div>
        <h3 class="mt-5">Thank you!</h3>
        <p class="p1-platform">Please wait...</p>

        <p class="mt-3 p-text">We are processing your payment and preparing your appointment. You will be redirected
          shortly.</p>
      </div>

      <div v-if="checkConnection" class="mt-3">
        <p class="small p-text mt-3">
          This taking longer than usual, please check your internet connection.
        </p>
      </div>

      <!-- manual refresh in case snapshot is not working -->
      <div v-if="manualRedirect" class="mt-3">
        <p class="small p-text mt-3">Could not finalize payment. Please check your payments, if something is missing make
          sure to contact our support team.</p>

        <button class="mt-3 jbtn jbtn-white" @click="redirectManually">Continue</button>
      </div>

    </section>

    <!-- <div class="wrapper section-secondary">
      <section id="section-support" class="
                    dashboard
                    section section-secondary
                    jpadding jpadding-20
                    section-unfixed
                  ">
        <button class="jcard jcard-dash">

          <h4> PLease wait.</h4>
          Thank you for booking the appointment.
          <br>
          We are processing your payment.
        </button>
      </section>
    </div> -->
  </div>
</template>



<script>
import C from "@/const";
import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit"
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "Redirection",
  data() {
    return {
      appointmentId: "",
      listenerRegistration: null,
      checkConnection: false,
      manualRedirect: false,
      timer: null,
    }
  },
  methods: {
    init() {
      if (!auth.currentUser)
        return;

      if (this.listenerRegistration != null)
        this.listenerRegistration();

      this.listenerRegistration = db.collection(C.COLLECTION.APPOINTMENTS).doc(this.appointmentId).onSnapshot((snap) => {
        const appointment = snap.data();

        // in case where backend deletes appointment when payment failed (update: should not happen, a check does not hurt though)
        if (!appointment) {
          this.$router.replace({
            name: 'PaymentFailed',
          });
          return;
        }

        appointment.id = this.appointmentId;
        if (!appointment.paid)
          return;

        this.$router.replace({
          name: 'AppointmentCardOwner',
          params: {
            appointmentId: this.appointmentId
          }
        });
      }, (error => {
        console.log(error);
        toast.error("Please check your internet connection");
      }));
    },
    setCountdownTimer() {
      var seconds = 0;
      var timer = setInterval(() => {
        seconds++;
        if (seconds % C.RETRY_INTERVAL == 0)
          this.init()
        if (seconds > C.CONNECTION_WARNING)
          this.checkConnection = true;
        if (seconds > C.MAXIMUM_WAITING_TIME) {
          this.checkConnection = false;
          clearInterval(timer);
          this.manualRedirect = true;
        }
      }, 1000);
    },
    redirectManually() {
      // refresh token, then go to my watches
      auth
        .currentUser.getIdTokenResult(true)
        .then((idTokenResult) => {
          // dont log userclaims in production mode
          if (!C.PRODUCTION) {
            console.log("CLAIMS: ");
            console.log(idTokenResult.claims);
            this.$store.dispatch("updateUser", idTokenResult);
            if (idTokenResult.claims.admin) {
              console.log("is admin");
            } else if (idTokenResult.claims.press) {
              console.log("is press");
            } else if (idTokenResult.claims.member) {
              console.log("is Member");
            }
          }

          // TODO: maybe use async logic to be sure this done before redirecting?
          db.collection(C.COLLECTION.USERS).doc(auth.currentUser.uid).update({
            refresh: false
          });

          this.$router.replace({ name: 'MyWatches' })
        });
    },

  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
  },
  created() {
    //get appointment id from params
    if (this.$route.params.appointmentId != null && this.$route.params.appointmentId != "") {
      this.appointmentId = this.$route.params.appointmentId;
      this.init();
      this.setCountdownTimer();
    } else {
      console.log("DID NOT GET appointmentId FROM PROPS");
      toast.error("Something went wrong");
    }
  },
  beforeDestroy() {
    if (this.listenerRegistration != null) this.listenerRegistration();
  }
};
</script>

<style scoped></style>
